// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"

//import "prismjs/themes/prism.css"
import("prismjs/themes/prism-solarizedlight.css")
// Highlighting for code blocks
// Numbered lines in code blocks
import("prismjs/plugins/line-numbers/prism-line-numbers.css")
//shell prompt
import("prismjs/plugins/command-line/prism-command-line.css")

// Self-host Google Fonts by fontsource
import "@fontsource/montserrat"
import "@fontsource/montserrat/500.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/700.css"
import "@fontsource/montserrat/800.css"
import "@fontsource/montserrat-alternates";
import "@fontsource/montserrat-alternates/700.css"
